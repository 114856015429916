<template>
  <v-container fluid>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Add Schedule</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <Editor :initial="dataItem" :itemsData="itemsData" @data="save" />
  </v-container>
</template>
<script>
import Editor from "../../_components/schedules/editor.vue";
export default {
  components: {
    Editor
  },
  data() {
    return {
      dataItem: {},
      itemsData: []
    };
  },
  methods: {
    save(data) {
      //logic comes here
      const url = "/schedules";
      const self = this;
      const id = this.$route.params.id;
      this.$store
        .dispatch("post", { url, data })
        .then(res => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push(`/survey/details/${id}/schedules`);
          location.reload();
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getOutlets() {
      const self = this;
      this.loading = true;
      //   const id = this.$route.params.id;
      this.$store
        .dispatch("get", `/v1/outlet`)
        .then(res => {
          console.log(res, "r");
          self.itemsData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getOutlets();
  }
};
</script>