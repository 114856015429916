<template>
  <v-container fluid>
    <v-row>
      <v-col cols="5">
        <v-menu transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{on}">
            <v-text-field :value="schedule.start_date" v-on="on" label="From"></v-text-field>
          </template>
          <v-date-picker v-model="schedule.start_date"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-menu transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{on}">
            <v-text-field :value="schedule.end_date" v-on="on" label="To"></v-text-field>
          </template>
          <v-date-picker v-model="schedule.end_date"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-text-field
          outlined
          dense
          label="Region"
          v-model="territory.descript"
          @click="openRegionModal"
        ></v-text-field>
        <!-- <v-autocomplete
          v-model="schedule.regionId"
          :items="territories"
          item-text="descript"
          item-value="id"
          label="Region"
        ></v-autocomplete>-->
      </v-col>
      <v-col cols="5">
        <!-- <v-autocomplete
                  v-model="record.bpartners"
                  :items="bpMasterData"
                  attach
                  chips
                  clearable
                  @input="searchInput = null"
                  :search-input.sync="searchInput"
                  deletable-chips
                  label="Outlets"
                  item-text="CardName"
                  item-value="id"
                  multiple
        ></v-autocomplete>-->
        <v-select
          v-model="schedule.outletCat"
          :items="bpMasterData"
          attach
          clearable
          deletable-chips
          @input="searchInput = null"
          :search-input.sync="searchInput"
          item-text="CardName"
          label="Outlet Categories"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-btn color="accent" @click="sendData">
      <v-icon left>mdi-content-save</v-icon>Save
    </v-btn>
    <!-- start of region dialog -->
    <v-dialog v-model="regiondialog" width="650px">
      <v-card>
        <v-toolbar color="accent" dark dense>
          <v-toolbar-title>Select Territory</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="regiondialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- territories -->
            <v-col cols="12">
              <v-treeview
                color="accent"
                :activatable="trueValue"
                :hoverable="trueValue"
                :selection-type="selectionType"
                open-all
                return-object
                item-text="descript"
                item-key="id"
                :items="territories"
                item-children="children_recursive"
                :search="search"
              >
                <template v-slot:label="{ item }">
                  <div @click="selectItem(item)">
                    <span style="font-weight: bold">{{ item.descript }}</span>
                  </div>
                </template>
              </v-treeview>
            </v-col>
            <!-- end -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of region dialog -->
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    itemsData: {
      type: Array
    }
  },
  computed: {
    ...mapGetters("territory", ["territories"]),
    ...mapGetters("salesEmployee", ["salesEmployees"])
  },
  data() {
    return {
      search: "",
      searchInput: null,
      record: {},
      bpMasterData: [],
      schedule: {},
      selectable: false,
      activatable: false,
      selectionType: "leaf",
      trueValue: true,
      territory: {},
      regiondialog: false,
      dialog: false,
      date1: null,
      date2: null
      //   outlets: []
    };
  },
  methods: {
    sendData() {
      this.schedule.survey_id = this.$route.params.id;
      this.schedule.regionId = this.territory.id;
      this.$emit("data", this.schedule);
    },
    openRegionModal() {
      this.regiondialog = true;
    },
    selectItem(data) {
      this.territory = data;
      this.regiondialog = false;
      const id = data.id;
      const self = this;
      self.loading = true;
      // api call
      this.$store
        .dispatch("get", `/territory/${id}`)
        .then(res => {
          console.log(res, "res");
          self.bpMasterData = res.outlets;
          self.filteredOutlets = res.outlets;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });

      // end
    }
  }
};
</script>